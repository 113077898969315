export default [
  {
    title: "15 Wellness, Fitness & Feel-Good Products We're Loving",
    image: "/images/RIT.PressLogo_People.png",
    url:
      "https://people.com/health/february-2018-wellness-fitness-products/#if-your-daily-vitamin-just-isnt-cutting-it",
  },
  {
    title:
      "Katerina Schneider Wants To Change Women's Health With Ritual, An Instagrammable Vitamin Company",
    image: "/images/RIT.PressLogo_Forbes.png",
    url: "",
  },
  {
    title:
      "Subscription boxes are the gifts that keep on giving — here are the best ones for everyone in your life",
    image: "/images/RIT.PressLogo_TheThirty.jpg",
    url:
      "http://www.businessinsider.com/best-subscription-box-christmas-gifts-2017-10/#food-and-drink-1",
  },
  {
    title:
      "10 Things You Should Know About Those Instagram-Famous Vitamins You're Seeing Everywhere",
    image: "/images/RIT.PressLogo_NewBeauty.png",
    url:
      "https://www.newbeauty.com/blog/dailybeauty/11892-ritual-vitamins-review/",
  },
  {
    title: "Best Prenatal Vitamins Based on In-Depth Reviews",
    image: "/images/consumers-advocate.png",
    url: "https://www.consumersadvocate.org/prenatal-vitamins",
  },
  {
    title:
      "How to Know If There's Scary Sh*t Hiding in Your Vitamins and Supplements",
    image: "/images/RIT.PressLogo_TheThirty.jpg",
    url: "",
  },
  {
    title: "Into the Gloss: Things we Finish",
    image: "/images/RIT.PressLogo_ITG.png",
    url: "https://intothegloss.com/2017/11/beauty-product-empties-review/",
  },
  {
    title:
      "Q&A With the Founder of Ritual, The Supplement Brand That is Blowin’ Up Your Insta Feed",
    image: "/images/RIT.PressLogo_Locale.jpg",
    url: "https://localemagazine.com/ritual-supplements/",
  },
  {
    title:
      "This Mom-Owned Vitamin Company Just Raised $10.5 Million in Funding",
    image: "/images/RIT_PressLogo_MyDomaine_jpg.png",
    url: "http://www.mydomaine.com/changing-careers-at-30?ps=home",
  },
  {
    title: "This Vitamin Company Just Raised $10.5 Million in Funding",
    image: "/images/RIT_PressLogo_Byrdie_jpg.png",
    url: "http://www.byrdie.com/ritual-vitamins",
  },
  {
    title:
      "Ritual Vitamins pulls in $10.5 million from Founders Fund to scale the business",
    image:
      "/images/RIT.TechCrunch_Logo_517b0ad7-0e16-4264-8bc6-eebf23da6bce.jpg",
    url:
      "https://techcrunch.com/2017/08/01/ritual-vitamins-pulls-in-10-5-million-from-founders-fund-to-scale-the-business/",
  },
  {
    title:
      "Instagram-Friendly Women's Vitamin Startup Ritual Raises $10.5M Series A",
    image: "/images/RIT.PressLogo_Forbes.png",
    url:
      "https://www.forbes.com/sites/clareoconnor/2017/08/01/instagram-friendly-womens-vitamin-startup-ritual-raises-10-5m-series-a/",
  },
  {
    title: "Are Cool Girl Vitamins A Thing? They Are Now Thanks To This Brand",
    image: "/images/RIT.PressLogo_Brit_Co.jpg",
    url: "http://www.brit.co/ritual-vitamin/",
  },
  {
    title: "Everyone You Know Is About To Be Taking This Vitamin",
    image: "/images/RIT_PressLogo_Byrdie_jpg.png",
    url: "http://www.byrdie.com/ritual-vitamins/",
  },
  {
    title: "The Controversial Redesign of the Lowly Multivitamin",
    image: "/images/LOGO_WIRED_316dfa19-f739-4752-abe4-cd7fef8cb35f.png",
    url: "https://www.wired.com/2016/11/ritual-daily-multivitamins/",
  },
  {
    title: "Vitamins Join the ‘Clean Label’ Bandwagon",
    image: "/images/LOGO_NYT_08ea7696-b2bc-4aaa-8f9d-587c1cd9f249.png",
    url:
      "https://www.nytimes.com/2016/05/27/business/vitamins-join-the-clean-label-bandwagon.html?_r=0",
  },
  {
    title: "Everyone You Know Is About To Start Taking This Buzzy Vitamin",
    image: "/images/LOGO_WELL_02dcd7a0-79fc-421c-a406-2aa676a2cdbe.png",
    url:
      "https://www.wellandgood.com/good-advice/non-gmo-vegan-vitamins-supplements-ritual/",
  },
  {
    title:
      "My Favorite Prenatal Vitamin Has A 20K Waitlist and I'm Not Surprised",
    image: "/images/RIT_PressLogo_Byrdie_jpg.png",
    url: "https://www.byrdie.com/ritual-prenatal-review-5076474",
  },
  {
    title:
      "Ritual Just Launched Multivitamins for Men To Fill Nutrient Gaps in Their Diets",
    image: "/images/best-products.png",
    url:
      "https://www.bestproducts.com/fitness/a33013445/ritual-essential-for-men-multivitamins/",
  },
  {
    title:
      "How These Seven Tech Diversity Leaders Are Tackling Diversity in 2017",
    image: "/images/RIT.PressLogo_FastCompany.png",
    url:
      "https://www.fastcompany.com/3069251/how-these-seven-tech-leaders-are-tackling-diversity-in-2017",
  },
  {
    title:
      "Chic, Direct-to-Consumer Vitamin Companies Are the Latest Wellness Trend",
    image: "/images/RIT.PressLogo_Fashionista.jpg",
    url: "http://fashionista.com/2017/03/best-startup-vitamins-benefits",
  },
  {
    title:
      "Ladies, Seven Stylish (And Practical) Items You'll Love Using All Year Long",
    image: "/images/RIT.PressLogo_Forbes.png",
    url:
      "https://www.forbes.com/sites/katiechang/2017/01/20/ladies-seven-stylish-and-practical-items-youll-love-using-all-year-long/#34c6143963bf",
  },
  {
    title: "The Best Prenatal Vitamins for a Healthy Pregnancy",
    image: "/images/healthline.png",
    url: "https://www.healthline.com/health/pregnancy/best-prenatal-vitamins",
  },
  {
    title: "These Chic New Vitamin Packs Will Fine-Tune Your Diet",
    image: "/images/RIT.PressLogo_Vogue.jpg",
    url:
      "http://www.vogue.com/article/gwyneth-paltrow-goop-wellness-vitamin-packs-doctor-designed-postnatal",
  },
  {
    title: "11 Best Multivitamins For Women in 2020, According to Experts",
    image: "/images/prevention.png",
    url: "",
  },
  {
    title:
      "From Moon Juice to Thinx: 6 Wellness Companies That Are Changing Everything",
    image: "/images/RIT_PressLogo_Byrdie_jpg.png",
    url: "http://www.byrdie.com/female-wellness-companies/slide2",
  },
  {
    title:
      "Create & Cultivate 100: Wellness | Katerina Schneider & Lauren Hayes, Ritual",
    image: "/images/RIT_PressLogo_Create_Cultivate_jpg.png",
    url:
      "http://www.createcultivate.com/blog/createcultivate100-wellness-ritual",
  },
  {
    title: "The 21 hottest women-founded startups to watch in 2017",
    image: "/images/RIT.PressLogo_BusinessInsider.jpg",
    url:
      "http://www.businessinsider.com/top-female-founded-startups-to-watch-2017-1",
  },
  {
    title:
      "How A Mother-To-Be Quit Her Career To Start A Multi-Million Dollar Company",
    image:
      "/images/RIT_PressLogo_MyDomaine_jpg_d56446e5-09df-46b9-9d8c-8283d6b61a36.png",
    url: "http://www.mydomaine.com/changing-careers-at-30/",
  },
  {
    title:
      "Women’s vitamin startup Ritual is now open for orders, with $3.5 million more from investors",
    image: "/images/RIT.TechCrunch_Logo.jpg",
    url:
      "https://techcrunch.com/2016/10/19/womens-vitamin-startup-ritual-is-now-open-for-orders-with-3-5-million-more-from-investors/",
  },
];
